/* locations list view */
.package-list-view {
    padding: 10px;
    .package-list-table-head-text {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.01em;
        display: flex;
        align-items: center;
    }
    
    .add-package-list-box {
        background: rgba(48, 127, 244, 0.02);
        border: 1px dashed var(--theme-deafult);
        border-radius: 10px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        color: var(--theme-deafult);
        margin-top: 10px;
    }
}