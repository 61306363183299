.total-card {
    text-align: center;
    gap: 20px;
    border-radius: 10px;
    padding: 20px 10px 10px 10px;
    box-sizing: border-box;
    background-color: #dad6ff;
}

.total-card:nth-of-type(2) {
    background-color: red;
}

.dashboard-card {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}