
.admin-list-item {
  border: 1px solid rgba(48, 127, 244, 0.1);
  border-radius: 10px;
  padding: 15px 0px;
  margin-top: 10px;
  margin-left: 3px;
  
  .admin-list-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
  
  .admin-list-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0px;
  }
  
  .admin-list-color {
    width: 6px;
    height: 100%;
    background-color: var(--theme-deafult);
    border-radius: 10px;
    position: absolute;
    left: -16px;
  }
  
  .admin-list-edit button {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--theme-deafult);
    border: none;
    background: transparent;
  }
}