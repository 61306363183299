.form-error-span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"]{
    border: 1px solid red !important;
}

input:invalid[focused="true"] ~ .form-error-span{
    display: block;
}