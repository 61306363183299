.custom-search-bar-container {
    background-color: rgba(115, 102, 255, 0.1);
    border-radius: 10px;

    input,
    input:focus,
    input:focus-within {
        background-color: transparent;
    }

    input:focus,
    input:focus-within {
        outline: none;
        box-shadow: none;
    }
}