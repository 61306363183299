.login-card {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    background: url(../../assets/images/login/login_bg.jpg);
    background-position: center;
    padding: 30px 12px;

    .bakhtar-logo {
        width: 100px;
        height: 100px;
    }
    .login-main {
        width: 450px;
        padding: 40px;
        border-radius: 10px;
        -webkit-box-shadow: 0 0 37px rgba(8,21,66,0.05);
        box-shadow: 0 0 37px rgba(8,21,66,0.05);
        margin: 0 auto;
        background-color: #fff;
        
        .show-hide span:before {
            content: "hide";
        }

        .show-hide span.show:before {
            content: "show";
        }

        .theme-form input {
            background-color: #f3f3ff;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }

        .theme-form label {
            font-size: 14px;
        }

        .login-card .login-main .theme-form label {
            font-size: 15px;
            letter-spacing: 0.4px;
        }

        .theme-form input:hover, .login-card .login-main .theme-form input:focus {
            border: 1px solid #b9c1cc;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
    }

}