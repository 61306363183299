.tag-content {
  font-family: "Roboto";
  font-style: normal;
  // padding: 20px 20px 10px 20px;
  background: rgba(48, 127, 244, 0.02);
  border: 1px solid rgba(48, 127, 244, 0.1);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .text-container-box {
    padding: 20px 20px 10px 20px;
  }
  .link-container {
    padding: 10px 10px 15px 10px;

    a {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      float: right;
    }
  }

  .tag-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tag-img {
      border-radius: 16px 16px 0 0;
      width: 100%;
      height: 150px;
      background-color: lightgray;
    }

    .tag-img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px 16px 0 0;
    }

    .tag-title {
      font-weight: 300;
      font-size: 16px;
      line-height: 30px;
      margin-top: 10px;
    }

    .tag-description {
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 10px;
    }
  }

  .tag-color-badge {
    height: 100px;
    width: 100px;
    z-index: 1;
    position: absolute;
    bottom: -60px;
    right: -50px;
    border-radius: 50%;
  }
}
