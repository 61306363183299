.w-avatar-container {
    width: 140px;
    height: 140px;
    background: #F8F8F8;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/images/icons/camera.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 1.25287px solid rgba(5,13,24,.12);

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        background-color: #fff;
    }

    img:hover,
    .brand-l img:hover {
        cursor: pointer;
        opacity: .1;
    }
}

.loader-avatar-image {
    color: #fff;
    font-weight: 800;
    background-color: #423d3dc2;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;
}

.photo-input-label {
    width: 100%;
    height: auto;
    border-radius: 50%;
    float: right;
    cursor: pointer;
    position: relative;
}

// File Upload styles
.progressbar {
    width: 60%;
    height: 7px;
    flex-grow: 1;
    background-color: #B9D4FC !important;
    border-radius: 9px;
}
.child-progressbar {
    background-color: var(--theme-deafult) !important;
    height: 7px;
    border-radius: 9px;
}

.uploaded-file {
    border: 1px solid var(--theme-deafult);
    padding: 8px;
    border-radius: 5px;
    color: var(--theme-deafult);
}