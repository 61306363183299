/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------


 */

// *** utils ***


@import "utils/variables";

:root {
    --theme-deafult: #FFC148;
    --theme-secondary: #f73164;
}

// *** base ***
@import "base/reset";
@import "base/typography";
@import "components/icons";
@import "components/tab";
//	*** pages ***
@import "pages/dashboard_default";
@import "layout/header";
@import "layout/sidebar";
@import "layout/box-layout";

/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark";
@import "themes/dark-sidebar";
// @import "themes/theme-customizer";
@import "themes/responsive";

@import "modal-video.scss";

.main-content-card {
    padding: 30px!important;
    border-radius: 20px;
    margin-bottom: 30px;
    border: none;

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey; 
        border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $primary-color; 
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $primary-color;
        opacity: 0.5; 
    }
}

.primary-color {
    color: $primary-color;
}

.main-list-hover:hover {
    background-color: rgba($color: $primary-color, $alpha: 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}