.data-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;

    .loader {
        width: 50px;
        height: 50px;
        border: 3px solid #f3f3f3;
        border-top: 3px solid #307FF4;
        border-radius: 50%;
        animation: spin .5s ease-out infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
    
        100% {
            transform: rotate(360deg);
        }
    }
}

